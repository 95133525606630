import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import styled, { css } from 'styled-components'
import { Link, useLocation } from 'react-router-dom'
import { useTranslation, Trans } from 'react-i18next'

import Container from '@material-ui/core/Container'
import Typography from '@material-ui/core/Typography'

import {
  Routes,
  TRIAL_PERIOD_ROUNDS,
  RESOURCES_LINK,
  TRIAL_PERIOD_COURSE_STRATEGIES,
} from 'src/utils/constants'
import {
  userPlanSelector,
  userIsTrialingSelector,
} from 'src/store/subscriptionSlice'
import User from 'src/modules/header/User'
import { localizeRoutePath } from 'src/utils/i18n'
import useBreakpoints from 'src/hooks/useBreakpoints'
import BurgerMenu from 'src/modules/header/BurgerMenu'
import { playerSelector } from 'src/store/playerSlice'
import Notifications from 'src/components/notifications'
import SmallLogo from 'src/assets/svgComponents/SmallLogo'
import { totalRoundsSelector } from 'src/store/roundSlice'
import DashboardFilters from 'src/modules/header/DashboardFilters'
import UpgradeButton from 'src/components/membership/UpgradeButton'
import DesktopNavigation from 'src/modules/header/DesktopNavigation'
import { totalCourseStrategiesSelector } from 'src/store/courseStrategySlice'
import PracticeDashboardFilters from './PracticeDashboardFilters'

const I18N_KEY = 'Navigation'

interface Props {
  isPlayer: boolean
  isCoachView: boolean
}

const NavContainer = styled.div`
  position: relative;
`

const LogoLink = styled(Link)`
  display: flex;
  align-items: center;

  svg {
    width: 24px;
  }
`

const CoachBanner = styled.div(
  ({ theme }) => css`
    padding: ${theme.spacing(1, 0)};
    background-color: ${theme.palette.primary.light};
  `
)

const PlayerBanner = styled.div(
  ({ theme }) => css`
    color: #fff;
    padding: ${theme.spacing(1, 0)};
    background-color: ${theme.palette.background.blueHighlight};
  `
)

const BannerContent = styled(Container)`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const WhiteText = styled(Typography)(
  ({ theme }) => css`
    color: ${theme.palette.background.paper};
  `
)

const ExitLink = styled(Link)`
  font-weight: 500;
`

const MainNav = styled(Container)`
  height: 78px;
  display: flex;
  align-items: center;
`

const StyledUpgradeButton = styled(UpgradeButton)(
  ({ theme }) => css`
    margin: 0;
    padding: ${theme.spacing(1, 2)};
  `
)

const Navigation: React.FC<Props> = ({ isPlayer, isCoachView }) => {
  const { t } = useTranslation()
  const { pathname } = useLocation()
  const { isDesktop } = useBreakpoints()

  const { uuid } = useSelector(playerSelector)
  const totalRounds = useSelector(totalRoundsSelector)
  const totalCourseStrategies = useSelector(totalCourseStrategiesSelector)
  const userNotSubscribed = !useSelector(userPlanSelector)
  const isPlayerTrialing = useSelector(userIsTrialingSelector)

  const roundsRemaining =
    totalRounds < 5 ? TRIAL_PERIOD_ROUNDS - totalRounds : 0
  const roundsText = roundsRemaining === 1 ? 'round' : 'rounds'

  const courseStrategiesRemaining =
    totalCourseStrategies < 2
      ? TRIAL_PERIOD_COURSE_STRATEGIES - totalCourseStrategies
      : 0
  const courseStrategiesText =
    roundsRemaining === 1 ? 'course strategy' : 'course strategies'

  const isPracticeDashboardPath: boolean =
    (pathname === localizeRoutePath(Routes.PracticeDashboard) && isPlayer) ||
    pathname ===
      localizeRoutePath(
        Routes.CoachPlayerPracticeDash.replace(':playerUuid', uuid)
      )

  const showFilters =
    // Coach and player have same Dashboard route, so we need to check if they are a player here as well
    (pathname === localizeRoutePath(Routes.Dashboard) && isPlayer) ||
    pathname ===
      localizeRoutePath(Routes.CoachPlayerDash.replace(':playerUuid', uuid)) ||
    pathname.includes(Routes.Categories) ||
    (pathname === localizeRoutePath(Routes.Insight) && isPlayer) ||
    pathname ===
      localizeRoutePath(
        Routes.CoachPlayerInsight.replace(':playerUuid', uuid)
      ) ||
    isPracticeDashboardPath

  const resourceRoute = useMemo(
    () => ({
      route: RESOURCES_LINK,
      label: t(`${I18N_KEY}.resources`, 'Resources'),
      external: true,
    }),
    [t]
  )

  const playerRoutes = useMemo(() => {
    return [
      {
        route: localizeRoutePath(Routes.Dashboard) as string,
        label: t(`${I18N_KEY}.playerDashboard`, 'Dashboard'),
      },
      {
        route: localizeRoutePath(Routes.Rounds) as string,
        label: t(`${I18N_KEY}.activity`, 'Activity'),
      },
      {
        route: localizeRoutePath(Routes.PracticeDashboard) as string,
        label: t(`${I18N_KEY}.practiceDashboard`, 'Practice'),
      },
      {
        route: localizeRoutePath(Routes.Insight) as string,
        label: t(`${I18N_KEY}.insight`, 'Insight'),
      },
      resourceRoute,
    ]
  }, [t, resourceRoute])

  const coachRoutes = useMemo(
    () => [
      {
        route: localizeRoutePath(Routes.Dashboard) as string,
        label: t(`${I18N_KEY}.coachDashboard`, 'Coach Dashboard'),
      },
      resourceRoute,
    ],
    [t, resourceRoute]
  )

  const coachPlayerRoutes = useMemo(() => {
    return [
      {
        route: localizeRoutePath(`/player/${uuid}`) as string,
        label: t(`${I18N_KEY}.playerDashboard`, 'Dashboard'),
      },
      {
        route: localizeRoutePath(`/player/${uuid}/practice`) as string,
        label: t(`${I18N_KEY}.practiceDashboard`, 'Practice'),
      },
      {
        route: localizeRoutePath(`/player/${uuid}${Routes.Rounds}`) as string,
        label: t(`${I18N_KEY}.activity`, 'Activity'),
      },
      {
        route: localizeRoutePath(`/player/${uuid}${Routes.Insight}`) as string,
        label: t(`${I18N_KEY}.insight`, 'Insight'),
      },
      resourceRoute,
    ]
  }, [t, uuid, resourceRoute])

  const selectAppropriateRoutes = () => {
    if (isPlayer) {
      return playerRoutes
    } else {
      return isCoachView ? coachPlayerRoutes : coachRoutes
    }
  }

  const routes = selectAppropriateRoutes()

  return (
    <NavContainer>
      {isCoachView && (
        <CoachBanner>
          <BannerContent>
            <WhiteText>
              <Trans i18nKey={`${I18N_KEY}.viewingCoach`}>
                Viewing as a Coach
              </Trans>
            </WhiteText>
            <ExitLink to={Routes.Dashboard}>
              <Trans i18nKey={`${I18N_KEY}.coachExit`}>
                Exit To Coach Dashboard
              </Trans>
            </ExitLink>
          </BannerContent>
        </CoachBanner>
      )}
      {isPlayer && userNotSubscribed && (
        <PlayerBanner>
          <BannerContent>
            <Typography>
              <Trans i18nKey={`${I18N_KEY}.freeExpired`}>
                You need to upgrade your account
              </Trans>
            </Typography>
            <StyledUpgradeButton />
          </BannerContent>
        </PlayerBanner>
      )}
      {isPlayer && isPlayerTrialing && (
        <PlayerBanner>
          <BannerContent>
            <Typography>
              <Trans i18nKey={`${I18N_KEY}.roundsRemaining`}>
                You have {{ roundsRemaining }} free {{ roundsText }} left to
                complete your evaluation. You have
                {{ courseStrategiesRemaining }} free {{ courseStrategiesText }}
                remaining as part of your free trial.
              </Trans>
            </Typography>
            <StyledUpgradeButton />
          </BannerContent>
        </PlayerBanner>
      )}
      <MainNav>
        <LogoLink to={Routes.Dashboard}>
          <SmallLogo />
        </LogoLink>
        {isDesktop ? (
          <DesktopNavigation routes={routes} />
        ) : (
          <BurgerMenu routes={routes} />
        )}
        <Notifications />
        <User />
      </MainNav>
      {showFilters &&
        !isDesktop &&
        (isPracticeDashboardPath ? (
          <PracticeDashboardFilters />
        ) : (
          <DashboardFilters />
        ))}
    </NavContainer>
  )
}

export default Navigation
