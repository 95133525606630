import { api } from 'src/utils/api'
import { getSinglePayloadFromResponse } from 'src/utils/helpers'

export const fetchRoundCount = async (
  isPlayer?: boolean,
  playerUuid?: string
) => {
  const endpoint = isPlayer
    ? `/round/count`
    : `overview/player/${playerUuid}/round/count`
  const roundCountResponse = await api.get(endpoint)
  return getSinglePayloadFromResponse(roundCountResponse)?.count
}

export const fetchLastRoundUpdated = async (
  isPlayer?: boolean,
  playerUuid?: string
) => {
  const endpoint = isPlayer
    ? `/round/latest`
    : `overview/player/${playerUuid}/round/latest`
  const latestRoundResponse = await api.get(endpoint)
  return getSinglePayloadFromResponse(latestRoundResponse)
}
