import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
  getUserInfo,
  authRequestFail,
  getPayloadFromResponse,
  isForbiddenOrUnauthorised,
  getSinglePayloadFromResponse,
} from 'src/utils/helpers'
import { getPlayer } from 'src/store/playerSlice'
import { userIsTrialingSelector } from 'src/store/subscriptionSlice'
import { api } from 'src/utils/api'
import { AppThunk } from 'src/store'
import { RootState } from 'src/store/rootReducer'
import { getSummary } from 'src/store/summarySlice'
import { getErrorToast, openToast } from 'src/store/toastSlice'
import { Hole, Lie, Round, RoundState } from 'src/utils/golfConstants'
import { PAGINATION_LIMIT } from 'src/utils/constants'
import { getNotifications } from './notificationSlice'
import {
  fetchLastRoundUpdated,
  fetchRoundCount,
} from 'src/service/roundService'

interface InitialState {
  isOpen: boolean
  rounds: Round[]
  isLoading: boolean
  totalRounds: number
  roundsLoaded: boolean
  selectedRound: Round | null
  isSelectedRoundLoading: boolean
  lastUpdatedRound: Round | null
  isSimulated: boolean
}

interface OpenPayload {
  isOpen: boolean
  selectedRoundUuid?: string
}

type LoadingPayload = Pick<InitialState, 'isLoading'>

interface RoundsPayload {
  rounds: Round[]
  totalRounds: number
}

interface RoundPayload {
  round: Round
}

type RoundRequest = Omit<Round, 'uuid' | 'holes' | 'state' | 'summary'>

const initialState: InitialState = {
  rounds: [],
  isOpen: false,
  totalRounds: 0,
  isLoading: false,
  selectedRound: null,
  roundsLoaded: false,
  isSelectedRoundLoading: false,
  lastUpdatedRound: null,
  isSimulated: false,
}

const { actions, reducer } = createSlice({
  name: 'round',
  initialState,
  reducers: {
    updateLoading: (state, action: PayloadAction<LoadingPayload>) => {
      const { isLoading } = action.payload

      state.isLoading = isLoading
    },
    updateLoaded: (state, action: PayloadAction<{ loaded: boolean }>) => {
      state.roundsLoaded = action.payload.loaded
    },
    updateDialogVisibility: (state, action: PayloadAction<OpenPayload>) => {
      const { isOpen, selectedRoundUuid } = action.payload

      state.isOpen = isOpen
      state.selectedRound =
        state.rounds.find(({ uuid }) => uuid === selectedRoundUuid) || null
    },
    updateTotalRounds: (state, action: PayloadAction<{ count: number }>) => {
      const { count } = action.payload

      state.totalRounds = count
    },
    updateSelectedRound: (state, action: PayloadAction<{ round: Round }>) => {
      state.selectedRound = action.payload.round
    },
    updateSelectedRoundByUuid: (
      state,
      action: PayloadAction<{ selectedRoundUuid: string }>
    ) => {
      const { selectedRoundUuid } = action.payload

      state.selectedRound =
        state.rounds.find(({ uuid }) => uuid === selectedRoundUuid) || null
    },
    updateSelectedRoundLoading: (
      state,
      action: PayloadAction<LoadingPayload>
    ) => {
      const { isLoading } = action.payload

      state.isSelectedRoundLoading = isLoading
    },
    updateRounds: (state, action: PayloadAction<RoundsPayload>) => {
      const { rounds, totalRounds } = action.payload

      state.rounds = rounds
      state.isLoading = false
      state.totalRounds = totalRounds ?? 0
    },
    updateRound: (state, action: PayloadAction<RoundPayload>) => {
      const { round } = action.payload
      const { rounds } = state
      const updatedRounds = rounds.slice()
      const roundIndex = updatedRounds.findIndex(
        element => element.uuid === round.uuid
      )

      if (roundIndex < 0) {
        updatedRounds.push(round)
      } else {
        updatedRounds[roundIndex] = round
      }

      state.rounds = updatedRounds
      state.selectedRound = round
      state.isSelectedRoundLoading = false
    },
    updateLastUpdatedRound: (
      state,
      action: PayloadAction<{ round: Round }>
    ) => {
      state.lastUpdatedRound = action.payload.round
    },
    reinitialiseRound: () => ({ ...initialState }),
  },
})

export default reducer
export const {
  updateRound,
  updateRounds,
  updateLoaded,
  updateLoading,
  reinitialiseRound,
  updateTotalRounds,
  updateSelectedRound,
  updateSelectedRoundByUuid,
  updateSelectedRoundLoading,
  updateDialogVisibility,
  updateLastUpdatedRound,
} = actions

// Selectors
export const roundSelector = (state: RootState) => state.round

export const totalRoundsSelector = createSelector(
  roundSelector,
  round => round.totalRounds
)

export const roundsLoadingSelector = createSelector(
  roundSelector,
  round => round.isLoading
)

export const roundsDialogOpenSelector = createSelector(
  roundSelector,
  round => round.isOpen
)

export const roundsSelector = createSelector(
  roundSelector,
  round => round.rounds
)

export const roundsLoadedSelector = createSelector(
  roundSelector,
  round => round.roundsLoaded
)

export const selectedRoundSelector = createSelector(
  roundSelector,
  ({ selectedRound }) => selectedRound
)

export const selectedRoundLoadingSelector = createSelector(
  roundSelector,
  round => round.isSelectedRoundLoading
)

export const lastUpdatedRoundSelector = createSelector(
  roundSelector,
  round => round.lastUpdatedRound
)

const convertIsLayupShotsToTeeLayup = (round: Round) => {
  round.holes?.forEach(hole => {
    hole.shots = hole.shots.map(shot => {
      if (shot?.isLayup) {
        shot.lie = Lie.TeeLayup
      }
      return shot
    })
  })
}

const convertTeeLayupShotsToIsLayup = (holes: Hole[]): Hole[] => {
  return holes.map(hole => {
    const updatedShots = hole.shots.map(shot => {
      if (shot.lie === Lie.TeeLayup) {
        return {
          ...shot,
          isLayup: true,
          lie: Lie.Tee,
        }
      }
      return shot
    })
    return {
      ...hole,
      shots: updatedShots,
    }
  })
}

// Action Creators
export const getRounds =
  (start: number = 0, teeLayupFlag?: false): AppThunk =>
  async (dispatch, getState) => {
    dispatch(updateLoading({ isLoading: true }))
    const { isPlayer, playerUuid } = getUserInfo(getState())
    const endpoint = isPlayer ? 'round' : `overview/player/${playerUuid}/round`

    try {
      const response = await api.get(endpoint, {
        params: { count: true, start, limit: PAGINATION_LIMIT },
      })
      const rounds: Round[] = getPayloadFromResponse(response)
      if (teeLayupFlag) {
        rounds.forEach(round => convertIsLayupShotsToTeeLayup(round))
      }
      const totalRounds = response.data.count || rounds.length

      dispatch(updateRounds({ rounds, totalRounds }))
      dispatch(updateLoaded({ loaded: true }))
    } catch (error: any) {
      dispatch(updateLoading({ isLoading: false }))
      if (isForbiddenOrUnauthorised(error)) {
        authRequestFail(dispatch)
      } else {
        dispatch(openToast(getErrorToast('Could not load rounds')))
      }
    }
  }

export const getRound =
  (uuid: string, teeLayupFlag?: false): AppThunk =>
  async (dispatch, getState) => {
    dispatch(updateSelectedRoundLoading({ isLoading: true }))
    const { isPlayer, playerUuid } = getUserInfo(getState())
    const endpoint = isPlayer
      ? `round/${uuid}`
      : `overview/player/${playerUuid}/round/${uuid}`

    try {
      const response = await api.get(endpoint)
      const round = getSinglePayloadFromResponse(response)
      if (teeLayupFlag) {
        convertIsLayupShotsToTeeLayup(round)
      }
      dispatch(updateRound({ round }))
    } catch (error: any) {
      dispatch(updateSelectedRoundLoading({ isLoading: false }))
      if (isForbiddenOrUnauthorised(error)) {
        authRequestFail(dispatch)
      } else {
        throw new Error()
      }
    }
  }

export const getRoundLastUpdated =
  (): AppThunk => async (dispatch, getState) => {
    const { isPlayer, playerUuid } = getUserInfo(getState())
    try {
      const round = await fetchLastRoundUpdated(isPlayer, playerUuid)
      dispatch(updateLastUpdatedRound({ round }))
    } catch (error: any) {
      if (isForbiddenOrUnauthorised(error)) {
        authRequestFail(dispatch)
      } else {
        if (
          error.response?.status === 404 &&
          error.response?.data?.message === 'No rounds entered'
        ) {
          // Fail silently or do we want to show an error to a new user?
          console.warn('No rounds found')
        } else {
          throw new Error()
        }
      }
    }
  }

export const createRound =
  (roundData: RoundRequest): AppThunk =>
  async (dispatch, getState) => {
    const { isPlayer, playerUuid } = getUserInfo(getState())
    const endpoint = isPlayer ? `round` : `overview/player/${playerUuid}/round`

    try {
      const response = await api.post(endpoint, roundData)
      const round = getSinglePayloadFromResponse(response)
      dispatch(updateSelectedRound({ round }))
      await dispatch(getRounds())
    } catch (error: any) {
      if (isForbiddenOrUnauthorised(error)) {
        authRequestFail(dispatch)
      }
      throw new Error('Could not save round')
    }
  }

export const putRound =
  (uuid: string, roundData: RoundRequest, teeLayupFlag: boolean): AppThunk =>
  async (dispatch, getState) => {
    const { isPlayer, playerUuid } = getUserInfo(getState())
    const endpoint = isPlayer
      ? `round/${uuid}`
      : `overview/player/${playerUuid}/round/${uuid}`

    try {
      const response = await api.put(endpoint, roundData)
      const round = getSinglePayloadFromResponse(response)
      if (teeLayupFlag) {
        convertIsLayupShotsToTeeLayup(round)
      }

      dispatch(updateRound({ round }))
    } catch (error: any) {
      if (isForbiddenOrUnauthorised(error)) {
        authRequestFail(dispatch)
      }
      throw new Error('Could not save round')
    }
  }

export const deleteRound =
  (uuid: string): AppThunk =>
  async (dispatch, getState) => {
    const { isPlayer, playerUuid } = getUserInfo(getState())
    const endpoint = isPlayer
      ? `round/${uuid}`
      : `overview/player/${playerUuid}/round/${uuid}`

    try {
      await api.delete(endpoint)
      await dispatch(getRounds())
      dispatch(getSummary())
    } catch (error: any) {
      if (isForbiddenOrUnauthorised(error)) {
        authRequestFail(dispatch)
      }
      throw new Error()
    }
  }

export const duplicateRound =
  (uuid: string): AppThunk =>
  async (dispatch, getState) => {
    const state = getState()
    const { isPlayer, playerUuid } = getUserInfo(state)
    const endpoint = isPlayer
      ? `round/${uuid}/duplicate`
      : `overview/player/${playerUuid}/round/${uuid}/duplicate`

    try {
      await api.post(endpoint)
      await dispatch(getRounds())
    } catch (error: any) {
      if (isForbiddenOrUnauthorised(error)) {
        authRequestFail(dispatch)
      }
      throw new Error()
    }
  }

export const saveHole =
  (index: number, hole: Hole, teeLayupFlag: boolean): AppThunk =>
  async (dispatch, getState) => {
    const state = getState()
    const isTrialing = userIsTrialingSelector(state)
    const currentRound = selectedRoundSelector(state)
    const { isPlayer, playerUuid } = getUserInfo(state)

    const uuid = currentRound!.uuid
    let holes = currentRound?.holes ? currentRound.holes.slice() : []
    if (teeLayupFlag) {
      holes = convertTeeLayupShotsToIsLayup(holes)

      hole.shots.forEach(shot => {
        if (shot.lie === Lie.TeeLayup) {
          shot.isLayup = true
          shot.lie = Lie.Tee
        }
      })
    }
    holes[index] = hole

    const endpoint = isPlayer
      ? `round/${uuid}/hole`
      : `overview/player/${playerUuid}/round/${uuid}/hole`

    try {
      const response = await api.post(endpoint, {
        holes,
      })
      const round: Round = getSinglePayloadFromResponse(response)
      if (teeLayupFlag) {
        convertIsLayupShotsToTeeLayup(round)
      }
      dispatch(updateRound({ round }))

      const roundCompleted =
        currentRound?.state === RoundState.Incomplete &&
        round.state === RoundState.Complete

      if (roundCompleted) {
        await dispatch(getNotifications())
      }

      // Refetch player when user enters 5th round (Evaluation period is over)
      if (roundCompleted && isTrialing && state.round.totalRounds >= 4) {
        await dispatch(getPlayer())
      }

      if (round.state !== RoundState.Incomplete) {
        dispatch(getSummary())
      }
    } catch (error: any) {
      if (isForbiddenOrUnauthorised(error)) {
        authRequestFail(dispatch)
      }
      throw new Error('Could not save hole')
    }
  }

export const getRoundCount = (): AppThunk => async (dispatch, getState) => {
  const { isPlayer, playerUuid } = getUserInfo(getState())
  try {
    const count = await fetchRoundCount(isPlayer, playerUuid)
    dispatch(updateTotalRounds({ count }))
  } catch (error: any) {
    if (isForbiddenOrUnauthorised(error)) {
      authRequestFail(dispatch)
    }
  }
}
