import React, { useContext, useState, MouseEvent } from 'react'
import { Box, Menu, MenuItem, Typography } from '@material-ui/core'
import { Trans } from 'react-i18next'
import {
  DataRow,
  TableCell,
  TitleCell,
} from 'src/components/dataDisplay/tableStyles'
import { I18N_KEY } from 'src/components/membership/common'
import { DateFormats } from 'src/utils/constants'
import { StrategyState } from 'src/utils/golfConstants'
import { TranslationContext } from 'src/utils/TranslationContext'
import { CourseStrategy } from 'src/models/course-strategy'
import styled, { css } from 'styled-components'
import { EndCell, IconButton } from 'src/modules/activity/common'
import CircleCheck from 'src/assets/svgComponents/CircleCheck'
import MoreIcon from '@material-ui/icons/MoreVert'
import GetAppIcon from '@material-ui/icons/GetApp'
import theme from '../../../styles/theme'
import { Theme } from '@material-ui/core/styles/createTheme'

interface Props {
  courseStrategy: CourseStrategy
  handleDeleteClick: (e: MouseEvent<HTMLLIElement>) => void
  handleRowClick: (e: MouseEvent<HTMLTableRowElement>) => void
  isDownloading: boolean
}

interface IconProps {
  $draft: boolean
}

const IconContainer = styled.div<IconProps>(
  ({ theme, $draft }) => css`
    width: 35px;
    height: 35px;
    display: flex;
    min-width: 35px;
    border-radius: 50%;
    position: relative;
    align-items: center;
    align-content: center;
    justify-content: center;
    background-color: ${$draft
      ? theme.palette.background.light
      : theme.palette.primary.light};

    svg {
      width: 90%;
    }

    ${theme.breakpoints.down('sm')} {
      display: none;
    }
  `
)

const DownloadingIconContainer = styled.div<{ theme: Theme }>`
  width: 35px;
  height: 35px;
  min-width: 35px;
  border-radius: 50%;
  display: flex;
  position: relative;
  align-items: center;
  align-content: center;
  justify-content: center;
  background-color: ${theme.palette.primary.light};
  color: ${theme.palette.text.white};
  margin-left: 0.25rem;
  svg {
    width: 90%;
  }

  ${theme.breakpoints.down('sm')} {
    display: none;
  }
`

const StyledDataRow = styled(DataRow)<{ clickable: boolean }>`
  cursor: ${({ clickable }) => (clickable ? 'pointer' : 'auto')};
  pointer-events: ${({ clickable }) => (clickable ? 'auto' : 'none')};
`

const TextContainer = styled.div(
  ({ theme }) => css`
    margin-left: ${theme.spacing(3)}px;
    ${theme.breakpoints.down('sm')} {
      margin-left: 0;
    }
  `
)

const StrategyText = styled(Typography)(
  () => css`
    text-transform: capitalize;
  `
)

const StyledTitleCell = styled(TitleCell)<{ $width: string }>(
  ({ $width }) => css`
    width: ${$width};
  `
)

const StyledCell = styled(TableCell)<{ $width: string; $textAlign: string }>(
  ({ $width, $textAlign }) => css`
    min-width: 130px;
    width: ${$width};
    text-align: ${$textAlign};
  `
)

const StyledEndCell = styled(EndCell)<{ $width: string }>(
  ({ $width }) => css`
    cursor: pointer;
    pointer-events: auto;
    width: ${$width};
  `
)

const CourseStrategyRow: React.FC<Props> = ({
  courseStrategy,
  handleRowClick,
  handleDeleteClick,
  isDownloading,
  ...props
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  const { format } = useContext(TranslationContext)!

  const { uuid, state, datePlayed, courseName, teeName, courseNotes } =
    courseStrategy
  const isDraft = state === StrategyState.Incomplete

  const openMenu = (event: MouseEvent<HTMLElement>) => {
    event.stopPropagation()
    setAnchorEl(event.currentTarget)
  }

  const closeMenu = () => {
    setAnchorEl(null)
  }

  return (
    <>
      <StyledDataRow
        id={uuid}
        onClick={handleRowClick}
        {...props}
        clickable={!isDraft && !isDownloading}
      >
        <StyledTitleCell $width="35%">
          <Box display="flex" alignItems="center">
            <IconContainer $draft={isDraft}>
              <CircleCheck />
            </IconContainer>
            {isDownloading ? (
              <DownloadingIconContainer>
                <GetAppIcon />
              </DownloadingIconContainer>
            ) : (
              ''
            )}
            <TextContainer>
              <StrategyText variant="body2">{courseName}</StrategyText>
              <Typography variant="caption" color="textSecondary">
                {format(new Date(datePlayed), DateFormats.TableRow)}
              </Typography>
            </TextContainer>
          </Box>
        </StyledTitleCell>
        <StyledCell align="center" $width="45%" $textAlign="left">
          <Typography>{courseNotes}</Typography>
        </StyledCell>
        <StyledCell align="center" $width="15%" $textAlign="center">
          <Typography>{teeName}</Typography>
        </StyledCell>
        <StyledEndCell align="center" $width="5%">
          <IconButton
            size="small"
            color="primary"
            onClick={openMenu}
            aria-label="Options"
          >
            <MoreIcon />
          </IconButton>
        </StyledEndCell>
      </StyledDataRow>
      <Menu
        elevation={1}
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={closeMenu}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <MenuItem
          onClick={e => {
            handleDeleteClick(e)
            closeMenu()
          }}
        >
          <Trans i18nKey={`${I18N_KEY}.delete`}>Delete</Trans>
        </MenuItem>
      </Menu>
    </>
  )
}

export default CourseStrategyRow
