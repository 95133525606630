import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AppThunk } from 'src/store'
import { RootState } from 'src/store/rootReducer'
import { getErrorToast, openToast } from 'src/store/toastSlice'
import { api } from 'src/utils/api'
import {
  getUserInfo,
  authRequestFail,
  getPayloadFromResponse,
  isForbiddenOrUnauthorised,
  getSinglePayloadFromResponse,
} from 'src/utils/helpers'
import { CourseStrategy } from 'src/models/course-strategy'
import { PAGINATION_LIMIT } from 'src/utils/constants'

interface InitialState {
  courseStrategies: CourseStrategy[]
  courseStrategiesLoaded: boolean
  courseStrategiesDialogOpen: boolean
  isLoading: boolean
  isOpen: boolean
  isDownloadDialogOpen: boolean
  selectedCourseStrategy: CourseStrategy | null
  totalCourseStrategies: number
  totalCourseStrategiesCompleted: number
}

interface StrategiesPayload {
  courseStrategies: CourseStrategy[]
  totalCourseStrategies: number
}

type LoadingPayload = Pick<InitialState, 'isLoading'>

type CourseStrategyRequest = Omit<CourseStrategy, 'uuid' | 'state'>

interface OpenPayload {
  isOpen: boolean
  selectedCourseStrategyUuid?: string
}

interface OpenStrategiesPayload {
  isOpen: boolean
}

const initialState: InitialState = {
  courseStrategies: [],
  courseStrategiesLoaded: false,
  courseStrategiesDialogOpen: false,
  isLoading: false,
  isOpen: false,
  isDownloadDialogOpen: false,
  selectedCourseStrategy: null,
  totalCourseStrategies: 0,
  totalCourseStrategiesCompleted: 0,
}

const { actions, reducer } = createSlice({
  name: 'courseStrategy',
  initialState,
  reducers: {
    updateLoading: (state, action: PayloadAction<LoadingPayload>) => {
      const { isLoading } = action.payload

      state.isLoading = isLoading
    },
    updateLoaded: (state, action: PayloadAction<{ loaded: boolean }>) => {
      state.courseStrategiesLoaded = action.payload.loaded
    },
    updateSelectedCourseStrategy: (
      state,
      action: PayloadAction<{ courseStrategy: CourseStrategy }>
    ) => {
      state.selectedCourseStrategy = action.payload.courseStrategy
    },
    updateStrategiesDialogVisibility: (
      state,
      action: PayloadAction<OpenStrategiesPayload>
    ) => {
      const { isOpen } = action.payload
      state.courseStrategiesDialogOpen = isOpen
    },
    updateDialogVisibility: (state, action: PayloadAction<OpenPayload>) => {
      const { isOpen, selectedCourseStrategyUuid } = action.payload

      state.isOpen = isOpen
      state.selectedCourseStrategy =
        state.courseStrategies.find(
          ({ uuid }) => uuid === selectedCourseStrategyUuid
        ) || null
    },
    updateDownloadDialogVisibility: (
      state,
      action: PayloadAction<{ isDownloadDialogOpen: boolean }>
    ) => {
      const { isDownloadDialogOpen } = action.payload

      state.isDownloadDialogOpen = isDownloadDialogOpen
    },
    updateCourseStrategies: (
      state,
      action: PayloadAction<StrategiesPayload>
    ) => {
      const { courseStrategies, totalCourseStrategies } = action.payload

      state.courseStrategies = courseStrategies
      state.totalCourseStrategies = totalCourseStrategies ?? 0
      state.isLoading = false
    },
    updateCourseStrategiesCompleted: (
      state,
      action: PayloadAction<{ count: number }>
    ) => {
      const { count } = action.payload
      state.totalCourseStrategiesCompleted = count
    },
  },
})

export default reducer
export const {
  updateCourseStrategies,
  updateStrategiesDialogVisibility,
  updateDialogVisibility,
  updateDownloadDialogVisibility,
  updateLoading,
  updateLoaded,
  updateSelectedCourseStrategy,
  updateCourseStrategiesCompleted,
} = actions

// Selectors
export const courseStrategySelector = (state: RootState) => state.courseStrategy

export const courseStrategiesSelector = createSelector(
  courseStrategySelector,
  courseStrategy => courseStrategy.courseStrategies
)

export const totalCourseStrategiesSelector = createSelector(
  courseStrategySelector,
  courseStrategy => courseStrategy.totalCourseStrategies
)

export const totalCourseStrategiesCompletedSelector = createSelector(
  courseStrategySelector,
  courseStrategy => courseStrategy.totalCourseStrategiesCompleted
)

export const courseStrategiesLoadingSelector = createSelector(
  courseStrategySelector,
  courseStrategy => courseStrategy.isLoading
)

export const courseStrategiesLoadedSelector = createSelector(
  courseStrategySelector,
  courseStrategy => courseStrategy.courseStrategiesLoaded
)

export const courseStrategyDialogOpenSelector = createSelector(
  courseStrategySelector,
  courseStrategy => courseStrategy.isOpen
)

export const courseStrategyDownloadDialogOpenSelector = createSelector(
  courseStrategySelector,
  courseStrategy => courseStrategy.isDownloadDialogOpen
)

export const selectedCourseStrategySelector = createSelector(
  courseStrategySelector,
  ({ selectedCourseStrategy }) => selectedCourseStrategy
)

// Action Creators
export const getCourseStrategies =
  (start: number = 0): AppThunk =>
  async (dispatch, getState) => {
    dispatch(updateLoading({ isLoading: true }))
    const { isPlayer, playerUuid } = getUserInfo(getState())
    const endpoint = isPlayer
      ? 'strategy'
      : `overview/player/${playerUuid}/strategy`

    try {
      const response = await api.get(endpoint, {
        params: { count: true, start, limit: PAGINATION_LIMIT },
      })
      const courseStrategies: CourseStrategy[] =
        getPayloadFromResponse(response)
      const totalCourseStrategies =
        response.data.count || courseStrategies.length

      dispatch(
        updateCourseStrategies({ courseStrategies, totalCourseStrategies })
      )
      dispatch(updateLoaded({ loaded: true }))
    } catch (error: any) {
      dispatch(updateLoading({ isLoading: false }))
      if (isForbiddenOrUnauthorised(error)) {
        authRequestFail(dispatch)
      } else {
        dispatch(openToast(getErrorToast('Could not load course strategies')))
      }
    }
  }

export const getCourseStrategiesCompletedCount =
  (): AppThunk => async dispatch => {
    const endpoint = `strategy/count`
    try {
      const response = await api.get(endpoint)
      const count = getSinglePayloadFromResponse(response)
      dispatch(updateCourseStrategiesCompleted({ count }))
    } catch (error: any) {
      if (isForbiddenOrUnauthorised(error)) {
        authRequestFail(dispatch)
      }
    }
  }

export const createCourseStrategy =
  (courseStrategyData: CourseStrategyRequest): AppThunk =>
  async (dispatch, getState) => {
    const { isPlayer, playerUuid } = getUserInfo(getState())
    const endpoint = isPlayer
      ? 'strategy'
      : `overview/player/${playerUuid}/strategy`

    try {
      const response = await api.post(endpoint, courseStrategyData)
      const courseStrategy = getSinglePayloadFromResponse(response)
      dispatch(updateSelectedCourseStrategy({ courseStrategy }))
      await dispatch(getCourseStrategies())
    } catch (error: any) {
      if (isForbiddenOrUnauthorised(error)) {
        authRequestFail(dispatch)
      }
      throw new Error('Could not save course strategy')
    }
  }

export const deleteCourseStrategy =
  (uuid: string): AppThunk =>
  async (dispatch, getState) => {
    const { isPlayer, playerUuid } = getUserInfo(getState())
    const endpoint = isPlayer
      ? `strategy/${uuid}`
      : `overview/player/${playerUuid}/strategy/${uuid}`

    try {
      await api.delete(endpoint)
      await dispatch(getCourseStrategies())
    } catch (error: any) {
      if (isForbiddenOrUnauthorised(error)) {
        authRequestFail(dispatch)
      }
      throw new Error()
    }
  }
