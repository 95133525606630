import React, { useMemo, MouseEvent, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { EndCell, StyledCell } from 'src/modules/activity/common'
import {
  PaginationContainer,
  TableContainer,
} from 'src/components/dataDisplay/tableStyles'
import {
  TableHead,
  Table,
  TableRow,
  TableBody,
  Typography,
  TablePagination,
} from '@material-ui/core'
import LoadingRow from 'src/modules/activity/LoadingRow'
import EmptyCourseStrategies from './EmptyCourseStrategies'
import {
  courseStrategiesLoadedSelector,
  courseStrategiesLoadingSelector,
  courseStrategiesSelector,
  deleteCourseStrategy,
  getCourseStrategies,
  totalCourseStrategiesSelector,
} from 'src/store/courseStrategySlice'
import { useTranslation } from 'react-i18next'
import { PAGINATION_LIMIT } from 'src/utils/constants'
import CourseStrategyRow from './CourseStrategyRow'
import ActionConfirmDialog from 'src/modules/activity/DeleteConfirmDialog'
import TablePaginationActions from 'src/components/dataDisplay/TablePaginationActions'
import { getErrorToast, getSuccessToast, openToast } from 'src/store/toastSlice'
import CourseStrategyError from './CourseStrategyError'
import { totalRoundsSelector } from '../../../store/roundSlice'
import { downloadCourseStrategyDocument } from '../../../service/courseStrategyService'
import useAppState from 'src/hooks/useAppState'

const I18N_KEY = 'CourseStrategyTable'

const NUMBER_OF_MINIMAL_ROUNDS = 3

const CourseStrategyTable: React.FC = () => {
  const [page, setPage] = useState(0)
  const [deleteUuid, setDeleteUuid] = useState('')
  const [isDeleting, setIsDeleting] = useState(false)
  const [isDownloading, setIsDownloading] = useState<string[]>([])
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { isPlayer, playerUuid } = useAppState()

  const courseStrategies = useSelector(courseStrategiesSelector)
  const totalCourseStrategies = useSelector(totalCourseStrategiesSelector)
  const courseStrategiesLoading = useSelector(courseStrategiesLoadingSelector)
  const courseStrategiesLoaded = useSelector(courseStrategiesLoadedSelector)
  const hasRoundsRestriction =
    useSelector(totalRoundsSelector) < NUMBER_OF_MINIMAL_ROUNDS

  const courseStrategiesCreated =
    courseStrategiesLoaded && totalCourseStrategies > 0

  const courseStrategyColumns = [
    t(`${I18N_KEY}.courseStrategyColumnsStrategy`, 'Strategy'),
    t(`${I18N_KEY}.courseStrategyColumnsNotes`, 'Notes'),
    t(`${I18N_KEY}.courseStrategyColumnsTee`, 'Tee'),
  ]

  const loadingArray = useMemo(
    () =>
      Array.from(
        Array(
          totalCourseStrategies > 0 && totalCourseStrategies < PAGINATION_LIMIT
            ? totalCourseStrategies
            : PAGINATION_LIMIT
        ).keys()
      ),
    [totalCourseStrategies]
  )

  const checkIfDownloading = (uuid: string): boolean => {
    return !!isDownloading.find(course => course === uuid)
  }

  const addToDownloadingList = (uuid: string) => {
    const downloadingList = [...isDownloading]
    downloadingList.push(uuid)
    setIsDownloading(downloadingList)
  }

  const removeFromDownloadingList = (uuid: string) => {
    const downloadingList = [...isDownloading]
    downloadingList.splice(downloadingList.indexOf(uuid), 1)
    setIsDownloading(downloadingList)
  }

  const handleRowClick = async (event: MouseEvent<HTMLTableRowElement>) => {
    const selectedCourseStrategyUuid = event.currentTarget.id
    // eslint-disable-next-line
    dispatch(
      openToast(
        getSuccessToast(
          t(
            `${I18N_KEY}.downloadCourseStrategyToast`,
            'Your download will begin soon. Please do not navigate from this page'
          )
        )
      )
    )
    addToDownloadingList(selectedCourseStrategyUuid)
    const selectedCourseStrategy = courseStrategies.filter(
      cs => cs.uuid === selectedCourseStrategyUuid
    )[0]
    const data = await downloadCourseStrategyDocument(
      selectedCourseStrategyUuid,
      isPlayer,
      playerUuid
    )
    const url = window.URL.createObjectURL(data)
    const link = document.createElement('a')
    link.href = url
    link.download = `${selectedCourseStrategy.courseName} - ${selectedCourseStrategy.datePlayed}.pdf`
    document.body.appendChild(link)
    link.click()
    link.remove()
    removeFromDownloadingList(selectedCourseStrategyUuid)
  }

  const generateDeleteClick =
    (uuid: string) => (event: MouseEvent<HTMLLIElement>) => {
      event.stopPropagation()
      setDeleteUuid(uuid)
    }

  const handleDelete = async () => {
    try {
      setIsDeleting(true)
      await dispatch(deleteCourseStrategy(deleteUuid))
      setIsDeleting(false)
    } catch {
      dispatch(
        openToast(
          getErrorToast(t(`${I18N_KEY}.deleteToast`, 'Could not delete round'))
        )
      )
    }
    setDeleteUuid('')
  }

  const closeDialog = () => {
    setDeleteUuid('')
  }

  const handleChangePage = async (
    event: MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    dispatch(getCourseStrategies(newPage * PAGINATION_LIMIT))
    setPage(newPage)
  }

  const contentToBeDisplayed = () => {
    if (hasRoundsRestriction) {
      return <CourseStrategyError />
    }
    if (courseStrategiesCreated) {
      return (
        <TablePagination
          page={page}
          component="div"
          count={totalCourseStrategies}
          rowsPerPageOptions={[]}
          rowsPerPage={PAGINATION_LIMIT}
          onPageChange={handleChangePage}
          ActionsComponent={TablePaginationActions}
        />
      )
    }
    return <EmptyCourseStrategies />
  }

  return (
    <>
      {courseStrategiesCreated && (
        <TableContainer>
          <Table aria-label="rounds table">
            <TableHead>
              <TableRow>
                {courseStrategyColumns.map((column, index) => (
                  <StyledCell
                    key={column}
                    align={index > 0 ? 'center' : 'left'}
                  >
                    <Typography variant="caption" color="textSecondary">
                      {column}
                    </Typography>
                  </StyledCell>
                ))}
                <EndCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {courseStrategiesLoading
                ? loadingArray.map(index => <LoadingRow key={index} />)
                : courseStrategies.map(courseStrategy => (
                    <CourseStrategyRow
                      courseStrategy={courseStrategy}
                      key={courseStrategy.uuid}
                      handleRowClick={handleRowClick}
                      handleDeleteClick={generateDeleteClick(
                        courseStrategy.uuid
                      )}
                      isDownloading={checkIfDownloading(courseStrategy.uuid)}
                    />
                  ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      <PaginationContainer>{contentToBeDisplayed()}</PaginationContainer>
      <ActionConfirmDialog
        open={!!deleteUuid}
        inProgress={isDeleting}
        handleClose={closeDialog}
        handleAction={handleDelete}
        title={t(
          `${I18N_KEY}.dialogTitle`,
          'Are you sure you want to delete this course strategy?'
        )}
        deleteText={t(`${I18N_KEY}.dialogDeleteText`, 'Delete')}
        description={t(
          `${I18N_KEY}.dialogDescription`,
          'This action can’t be undone.'
        )}
        width={550}
      />
    </>
  )
}

export default CourseStrategyTable
